.app {
    cursor: url(derp.png), auto;
}



a {
    cursor: url(self\ aware.png), pointer;
    color: pink;
}

a:hover {
    color: purple;
}

button {
    cursor: url(self\ aware.png), pointer !important;
}

.slider {
    cursor: url(images/HEART\ CURSOR/horizontal.png), pointer !important;
}